import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox';
import Swiper, { Navigation, Pagination, Controller } from 'swiper';
// import { TweenMax, Expo } from 'gsap/all';

import Plyr from 'plyr';
// import { data } from 'autoprefixer';
import { gsap } from 'gsap';
import mapBox from './inc/hmMaps';
import dataStore from './inc/dataStore';
import { mobileSubmenu, hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';
import observeIntersection from './inc/observeIntersection';
import scriptLoader from './inc/scriptLoader';
import devAt from './inc/devAt';

Swiper.use([Navigation, Pagination, Controller]);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    if (dataStore.hasScrollbar !== true) {
        $('body').removeClass('scrollbar');
    }

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    /* observe element intersection example +++++++++++++++++++++++++++++*/

    function initMap() {
        // load map script and initialize map here
        // for testing:
        scriptLoader(
            // 'themeVars' is a localized variable from WordPress
            // eslint-disable-next-line no-undef
            [`${themeVars.assetsUrl}dist/js/mapbox/mapbox-gl.js`],
            mapBox
        );
    }

    observeIntersection('#map', initMap, true);

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();

    /* parallax ++++++++++++++++++++++++++++++++++++++++++*/
    parallaxImg();

    /* header trans out/in ++++++++++++++++++++++++++++++++++++++++++*/
    setInterval(() => {
        if (dataStore.didScroll) {
            hasScrolled();
            dataStore.didScroll = false;
        }
    }, 250);

    $('.scrollToNext').on('click', (e) => {
        e.preventDefault();

        const btn = $(e.currentTarget);
        const intro = btn.closest('.intro');
        const { top } = intro.next().offset();

        $('html, body').animate({ scrollTop: top }, 350);
    });

    $('.scrollTop').on('click', (e) => {
        e.preventDefault();

        $('html, body').animate({ scrollTop: '0' }, 500);
    });

    $('.slideStep').on('click', (e) => {
        e.preventDefault();
        const step = $(e.currentTarget);
        const index = step.data('id');
        const top = $('#vertSlider').offset().top + (dataStore.winHeight * index);

        $('html, body').animate({ scrollTop: top }, 300);
    });

    /* stickyImg ++++++++++++++++++++++++++++++++++++++++++*/
    stickyImgSetWrapSize();

    /* Formular ++++++++++++++++++++++++++++++++++++++++++*/

    if ($('.gfield input, .gfield textarea, .gfield select').length) {
        jQuery(document).bind('gform_post_render', () => {
            $('.gfield input, .gfield textarea, .gfield select').each((i, el) => {
                const input = $(el);
                const formField = input.closest('.gfield');

                if (input.val() !== '') {
                    formField.addClass('focus');
                }
            });

            moveFieldLabels();
        });

        moveFieldLabels();
    }

    /* heading fix without intro ++++++++++++++++++++++++++++++++++++++++++*/

    if ($('h1').length === 0) {
        const el = document.querySelector('h2');
        el.outerHTML = `<h1 class="h2">${el.innerHTML}</h1>`;
    }

    /* Subnavi image swap ++++++++++++++++++++++++++++++++++++++++++*/

    if ($('.subMenuWrap').length) {
        $('.menu-item-has-children').on('mouseenter', (e) => {
            const el = e.currentTarget;
            const li = $(el).find('.subMenuWrap > ul > li:first-child');
            const img = $(li).data('img');
            const imgWrap = $(el).find('.navImgWrap img');
            imgWrap.attr('src', img);
        });

        const li = $('.subMenuWrap > ul > li');

        li.on('mouseenter', (e) => {
            const el = e.currentTarget;
            const img = $(el).data('img');
            const wrap = $(el).closest('.subMenuWrap');
            const imgWrap = wrap.find('.navImgWrap img');
            imgWrap.attr('src', img);

            wrap.addClass('hovered');
        }).on('mouseout', (e) => {
            const el = e.currentTarget;
            const wrap = $(el).closest('.subMenuWrap');
            wrap.removeClass('hovered');
        });
    }

    /* video popup ++++++++++++++++++++++++++++++++++++++++++*/

    $('.vidPopupTrigger').on('click', (e) => {
        e.preventDefault();

        const id = $(e.currentTarget).data('id');
        const video = $(e.currentTarget).data('video');
        const startimg = $(e.currentTarget).data('startimg');

        if (video !== '') {
            const popup = $(`#vidPopup${id}`);

            dataStore.plyrInit = $(`#vidPopup${id} *`).length;

            if (dataStore.plyrInit === 0) {
                popup.append(`<video id="player${id}" playsinline controls data-poster="${startimg}">
                <source src="${video}" type="video/mp4" /></video>`);
            }

            new Fancybox(
                [
                    {
                        src: `#vidPopup${id}`,
                        type: 'inline',
                    },
                ],
                {
                    mainClass: 'fancyVidContainer',
                    on: {
                        init: () => {
                            if (dataStore.plyrInit === 0) {
                                dataStore.plyr = new Plyr(`#player${id}`);
                            }
                        },
                        ready: () => {
                            dataStore.plyr.play();
                        },
                        destroy: () => {
                            // console.log('plyr pause');
                            dataStore.plyr.pause();
                        }
                    },
                }
            );
        }
    });

    /* Team Mitglieder ++++++++++++++++++++++++++++++++++++++++++*/

    $('.teamMember').on('mousemove', (e) => {
        mouseFollow(e, '.memberOverlay');
    }).on('mouseout', () => {
        dataStore.activeMouseFollow = false;
        dataStore.currentFollower = undefined;
    });

    $('.hoverCircleWrap').on('mousemove', (e) => {
        mouseFollow(e, '.hoverCircle');
    }).on('mouseout', () => {
        dataStore.activeMouseFollow = false;
        dataStore.currentFollower = undefined;
    });

    $('.dragHoverWrap').on('mousemove', (e) => {
        mouseFollow(e, '.dragHover');
    }).on('mouseout', () => {
        dataStore.activeMouseFollow = false;
        dataStore.currentFollower = undefined;
    });

    /* Intersection Observer  ++++++++++++++++++++++++++++++++++++++++++*/
    observeIntersection('.fadeIn', (isIntersecting, e) => {
        if (isIntersecting) {
            $(e.target).not('.vis').addClass('vis');
        }
    }, true);

    observeIntersection('.fadeInRow', (isIntersecting, e) => {
        if (isIntersecting) {
            $(e.target)
                .find('> div, > li, > p, > span, > h1, > h2, > h3, > h4, > h5, > h6, > a')
                .not('.vis')
                .each((i, el) => {
                    setTimeout(() => {
                        $(el).addClass('vis');
                    }, 150 * i);
                });
        }
    }, true);

    observeIntersection('.counters', (isIntersecting, e) => {
        if (isIntersecting) {
            const counters = $(e.target);
            const countNumber = counters.find('.count__number');

            for (let countI = 0; countI < countNumber.length; countI++) {
                const count = countNumber[countI];
                const countedNumber = $(count).attr('data-count');
                const parent = $(count).parent();

                setTimeout(() => {
                    $({ num: 0 }).animate({ num: countedNumber }, {
                        duration: 2500,
                        easing: 'swing',
                        step: (num) => {
                            $(count).html(Math.floor(num));
                        },
                        complete: () => {
                            $(count).html(countedNumber);
                            parent.addClass('finished');
                        }
                    });
                }, 150 * countI);
            }
        }
    }, true);

    observeIntersection('.videoItem', videoStartStop, false, 0, undefined);

    function videoStartStop(isIntersecting, entry) {
        // console.log('test: ', isIntersecting, entry);

        if (isIntersecting) {
            entry.target.play();
        } else {
            entry.target.pause();
        }
    }

    observeIntersection('#vertSlider .layer', (isIntersecting, e) => {
        if (isIntersecting) {
            const projects = document.getElementsByClassName('layer');
            for (let index = 0; index < projects.length; index++) {
                projects[index].classList.remove('current');
            }

            const i = $(e.target).data('id');

            const slideSteps = document.getElementsByClassName('slideStep');
            for (let index = 0; index < slideSteps.length; index++) {
                slideSteps[index].classList.remove('current');
            }
            slideSteps[i].classList.add('current');
            e.target.classList.add('current');
            e.target.classList.add('active');
        } else {
            const i = $(e.target).data('id');
            const slideSteps = document.getElementsByClassName('slideStep');
            slideSteps[i].classList.remove('current');

            let hasCurrent = false;
            for (let index = 0; index < slideSteps.length; index++) {
                if ($(slideSteps[index]).hasClass('current')) {
                    hasCurrent = true;
                }
            }

            if (hasCurrent === false) {
                slideSteps[i - 1].classList.add('current');
            }

            e.target.classList.remove('current');
            e.target.classList.remove('active');
        }
    }, false, 0, null, '-50% 0% -50% 0%');

    // advantages

    // observeIntersection('.advantageImg', advantages, false, 1, undefined, '100% 0% -350px 0%');
    observeIntersection('.advantageImg', (isIntersecting, e) => {
        e.target.classList.toggle('fixed', isIntersecting);
    }, false, 0, undefined, '0% 0% -50% 0%');

    // function advantages(isIntersecting, entry) {
    //     // console.log('test: ', isIntersecting, entry);
    //     entry.target.classList.toggle('fixed', isIntersecting);
    // }

    observeIntersection('.journeyItem .circle', (isIntersecting, e) => {
        if (isIntersecting) {
            const parent = $(e.target).closest('.journeyItem');
            parent.addClass('active').addClass('current');
            const index = parent.index();
            $('.cjImg').eq(index).addClass('fixed');
        } else {
            const parent = $(e.target).closest('.journeyItem');
            parent.removeClass('active');
            const index = parent.index();
            $('.cjImg').eq(index).removeClass('fixed');
        }
    }, false, 0, undefined, '0% 0% -50% 0%');

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Sonstiges +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    customerJournesResizes();

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    /* Ffancybox  ++++++++++++++++++++++++++++++++++++++++++*/
    Fancybox.bind(
        '.wp-block-gallery a[href$=jpg], .wp-block-gallery a[href$=png], .wp-block-gallery a[href$=webp]',
        {
            groupAll: true,
        }
    );

    Fancybox.bind(
        '.wp-block-image a[href$=jpg], .wp-block-image a[href$=png], .wp-block-image a[href$=webp]'
    );

    /**** Map Box ***/
    // if ($('#map').length) {
    //     mapBox();
    // }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.didScroll = true;

        if (dataStore.activeMouseFollow === true) {
            dataStore.currentFollower.removeClass('active');
            dataStore.activeMouseFollow = false;
        }

        dataStore.docScroll = $(window).scrollTop();

        // if (dataStore.touch === false && dataStore.activeMouseFollow === true) {
        //     dataStore.mouseYScroll = dataStore.mouseY
        //     + (dataStore.docScroll - dataStore.currentFollowerWrap.offset().top);

        //     if (dataStore.currentFollower) {
        //         dataStore.currentFollower.css({
        //             transform: `translate3d(${dataStore.mouseX}px,${dataStore.mouseYScroll}px,0)`,
        //             '-webkit-transform': `translate3d(${dataStore.mouseX}px,${dataStore.mouseYScroll}px,0)`
        //         });
        //     }
        // }

        if (dataStore.docScroll > dataStore.bgImgWrapTop - dataStore.docHeight) {
            dataStore.imageScroll = dataStore.TransAmount * (dataStore.bgImgWrapTop - dataStore.docScroll);

            dataStore.bgImg.css({
                transform: `translate(0,${-1 * dataStore.imageScroll}px)`,
                '-webkit-transform': `translate(0,${-1 * dataStore.imageScroll}px)`
            });
        }

        // Customer Journey Scroll
        customerJourneyScroll();
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    dataStore.hasScrollbar = window.innerWidth > document.documentElement.clientWidth;
    if (dataStore.hasScrollbar !== true) {
        $('body').removeClass('scrollbar');
    }

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    mobileSubmenu();
    // herrlichesMenu.destroy();
    // herrlichesMenu.init();

    customerJournesResizes();

    parallaxImg();

    $('.history').each((i, el) => {
        const parentId = $(el).attr('id');
        const timelineItems = $(`#${parentId}`).find('.timeline li');

        timelineResize(timelineItems, parentId);
    });

    stickyImgSetWrapSize();
}

$(window).on('load', () => {
    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/

    $('.imgSliderBlock').each((i, el) => {
        const parentId = $(el).attr('id');
        const imgSlider = new Swiper(`#${parentId} .imgSlider`, {
            // Optional parameters
            loop: true,
            autoHeight: true,
            on: {
                init: () => {
                    // $(e.el).addClass('vis');
                    lazyloadUpdate();
                }
            },
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
        });

        imgSlider.on('slideChange', () => {
            lazyloadUpdate();
        });

        if (dataStore.touch === false) {
            imgSlider.on('touchMove', () => {
                touchMove();
            });
        }
    });

    $('.history').each((i, el) => {
        const parentId = $(el).attr('id');
        const timeline = $(`#${parentId}`).find('.timeline');

        const historyLine = $(`#${parentId}`).find('.historyLine');
        const historyLineLeft = historyLine.offset().left;

        const timelineItems = $(`#${parentId}`).find('.timeline li');

        timelineResize(timelineItems, parentId);

        timeline.css({
            transform: `translate(-${dataStore.timeLineItems[0] - historyLineLeft}px,0)`
        });

        const historySwiper = new Swiper(`#${parentId} .historySwiper`, {
            slidesPerView: 1,
            loop: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: `#${parentId} .swiper-button-next`,
                prevEl: `#${parentId} .swiper-button-prev`,
            },
            on: {
                init: () => {
                    // $(e.el).addClass('vis');
                    lazyloadUpdate();
                }
            }
        });

        historySwiper.on('slideChange', () => {
            lazyloadUpdate();
            const index = historySwiper.realIndex;

            const contents = $(`#${parentId}`).find('.historyContents li');
            setTimeLineSize(parentId, index, true);

            contents.removeClass('current');
            contents.eq(index).addClass('current');

            // const timelineItems = $(`#${parentId}`).find('.timeline li');
            timelineItems.removeClass('current');
            timelineItems.eq(index).addClass('current');

            timeline.css({
                transform: `translate(-${dataStore.timeLineItems[index] - historyLineLeft}px,0)`
            });
        });

        timeline.find('li').on('click', (e) => {
            const index = $(e.target).parent().index();
            historySwiper.slideTo(index + 1);
        });

        if (dataStore.touch === false) {
            historySwiper.on('touchMove', () => {
                touchMove();
            });
        }
    });

    $('.testimonials').each((i, el) => {
        const parentId = $(el).attr('id');

        const testimonialSlider = new Swiper(`#${parentId} .testimonialSlider`, {
            slidesPerView: 'auto',
            loop: true,
            navigation: {
                nextEl: `#${parentId} .swiper-button-next`,
                prevEl: `#${parentId} .swiper-button-prev`,
            },
            pagination: {
                el: `#${parentId} .swiper-pagination.progressbar`,
                type: 'progressbar',
            },
            // breakpoints: {
            //     640: {
            //         slidesPerView: 2
            //     },
            //     768: {
            //         slidesPerView: 4
            //     },
            //     1024: {
            //         slidesPerView: 5
            //     }
            // }
        });

        testimonialSlider.on('slideChange', () => {
            const index = testimonialSlider.realIndex + 1;
            let currentindex = index;

            if (index < 10) {
                currentindex = `0${index}`;
            }

            $(`#${parentId}`).find('.fraction .current').text(currentindex);
        });
    });

    $('.projectslider').each((i, el) => {
        const parentId = $(el).attr('id');

        const swiper1 = new Swiper(`#${parentId} .projectSwiperMain`, {
            slidesPerView: 1,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: `#${parentId} .swiper-button-next`,
                prevEl: `#${parentId} .swiper-button-prev`,
            },
            on: {
                init: () => {
                    // $(e.el).addClass('vis');
                    lazyloadUpdate();
                }
            }
        });

        swiper1.on('slideChange', () => {
            lazyloadUpdate();
        });

        if (dataStore.touch === false) {
            swiper1.on('touchMove', () => {
                touchMove();
            });
        }

        const swiper2 = new Swiper(`#${parentId} .projectSwiperContent`, {
            slidesPerView: 1,
            loop: true,
            centeredSlides: true,
            autoHeight: true,
            allowTouchMove: false,
            pagination: {
                el: `#${parentId} .swiper-pagination.progressbar`,
                type: 'progressbar',
            }
        });

        // swiper1.controller.control = swiper2;
        // swiper2.controller.control = swiper1;

        swiper1.on('slideChange', () => {
            console.log('hier');
            console.log(swiper1.realIndex);

            const index = swiper1.realIndex + 1;
            let currentindex = index;

            if (index < 10) {
                currentindex = `0${index}`;
            }

            swiper2.slideTo(index);

            $(`#${parentId}`).find('.fraction .current').text(currentindex);
        //     // $('#projectsTextWrapper li.current').removeClass('current');
        //     // $(`#projectsTextWrapper li[data-item=${swiper1.realIndex}]`).addClass('current');
        //     // $('#projectsTextWrapper ul').css({
        //     //     'min-height': `${dataStore.projectsHeights[swiper1.realIndex + 1]}px`
        //     // });
        });
    });

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }

    parallaxImg();
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

/* News Date Scroller  ++++++++++++++++++++++++++++++++++++++++++*/
let currentScrollAtWindowBottomHalf = '';
// const cjTranslate = 0;
let stepsDateScale = 0;
function customerJourneyScroll() {
    if (dataStore.customerJourneyLength) {
        currentScrollAtWindowBottomHalf = dataStore.docScroll + dataStore.winHeight / 2;

        stepsDateScale = (currentScrollAtWindowBottomHalf - dataStore.vertProgressBarOffsetTop)
        / dataStore.vertProgressBarOffsetHeight;

        gsap.to('.vertProgressBar', {
            scaleY: stepsDateScale,
            x: 0,
            y: 0,
            ease: 'Expo.easeOut',
            overwrite: true,
        });
    }
}

function mouseFollow(e, item) {
    dataStore.activeMouseFollow = true;
    // console.log('mouseFollow');

    dataStore.currentFollowerWrap = $(e.currentTarget);
    dataStore.currentFollower = dataStore.currentFollowerWrap.find(item);
    dataStore.currentFollower.addClass('active');
    dataStore.mouseX = e.pageX - dataStore.currentFollowerWrap.offset().left;
    dataStore.mouseY = e.pageY - dataStore.currentFollowerWrap.offset().top;

    // console.log('target', $(e.currentTarget));
    // console.log('offset', $(e.target).offset().top);

    dataStore.currentFollower.css({
        transform: `translate3d(${dataStore.mouseX}px,${dataStore.mouseY}px,0)`,
        '-webkit-transform': `translate3d(${dataStore.mouseX}px,${dataStore.mouseY}px,0)`
    });
}

function timelineResize(timelineItems, parentId) {
    setTimeLineSize(parentId, 0, false);

    timelineItems.each((a, item) => {
        if (a === 0) {
            let offset = (dataStore.docWidth / 2 - 30);

            if (dataStore.docWidth > 1200) {
                offset = 15;
            }

            dataStore.timeLineItems.push($(item).offset().left + ($(item).find('span').width()) - offset);
        } else {
            let offset = (dataStore.docWidth / 2 - 15);

            if (dataStore.docWidth >= 1200) {
                offset = 25;
            }

            dataStore.timeLineItems.push($(item).offset().left + ($(item).find('span').width() / 2) - offset);
        }
    });
}

function setTimeLineSize(parentId, item, offset) {
    if (dataStore.docWidth >= 992) {
        const line = $(`#${parentId}`).find('.line');
        const lineTop = line.offset().top;

        const contents = $(`#${parentId}`).find('.historyContents li');

        let lineHeight = 0;

        if (offset === true) {
            lineHeight = contents.eq(item).offset().top - lineTop + contents.eq(item).height() - 15;
        } else {
            lineHeight = contents.eq(item).offset().top - lineTop + contents.eq(item).height() - 5;
        }

        line.css({
            transform: `scaleY(${lineHeight / line.height()})`
        });
    }
}
function touchMove() {
    dataStore.activeMouseFollow = true;
    dataStore.mouseX = window.event.pageX;
    dataStore.mouseY = window.event.pageX;

    const offsetTop = dataStore.currentFollowerWrap.offset().top;
    const offsetLeft = dataStore.currentFollowerWrap.offset().left;

    const left = `${dataStore.mouseX - offsetLeft}px`;
    const top = `${window.event.pageY - offsetTop}px`;

    if (dataStore.currentFollower) {
        dataStore.currentFollower.css({
            transform: `translate3d(${left},${top},0)`,
            '-webkit-transform': `translate3d(${left},${top},0)`
        });
    }
}

function stickyImgSetWrapSize() {
    if (dataStore.docWidth >= 1200) {
        $('.stickyImg').each((i, el) => {
            const height = $(el).height();
            const imageWrapperInner = $(el).find('.imageWrapperInner');
            imageWrapperInner.outerHeight(height);
        });
    }
}

function customerJournesResizes() {
    if (dataStore.customerJourneyLength) {
        const journeyItems = $('.journeyItem');
        const firstItem = journeyItems.eq(0);
        const top = firstItem.height() / 2 + 16;

        const lastItem = journeyItems.eq(journeyItems.length - 1);
        const bottom = lastItem.height() / 2;

        dataStore.progressVertWrap.css({
            top: `${top}px`,
            bottom: `${bottom}px`
        });

        dataStore.vertProgressBarOffsetTop = dataStore.vertProgressBar.offset().top;
        dataStore.vertProgressBarOffsetHeight = dataStore.vertProgressBar.height();
    }
}

function hasScrolled() {
    // if (dataStore.docWidth >= dataStore.smMax) {
    // Make sure they scroll more than delta
    if (Math.abs(dataStore.lastScrollTop - dataStore.docScroll) <= dataStore.delta) {
        return;
    }

    // If they scrolled down and are past the navbar, add class .nav-up.
    if (dataStore.docScroll > dataStore.lastScrollTop && dataStore.docScroll > dataStore.headerHeight) {
        // Scroll Down
        dataStore.scrollHeader.removeClass('navDown').addClass('scrolled');
    } else if (dataStore.docScroll < (dataStore.headerHeight + 100)) {
        dataStore.scrollHeader.removeClass('navDown');

        if (dataStore.docScroll < dataStore.headerHeight) {
            // top reached
            dataStore.scrollHeader.removeClass('scrolled');
        }
    } else if (dataStore.docScroll + dataStore.winHeight < dataStore.docHeight) {
        // Scroll Up
        dataStore.scrollHeader.addClass('navDown');
    }
    // }
    dataStore.lastScrollTop = dataStore.docScroll;
}

function parallaxImg() {
    if (dataStore.bgImgWrap.length) {
        dataStore.bgImgWrapTop = dataStore.bgImgWrap.offset().top;
        dataStore.bgImgWrapHeight = dataStore.bgImgWrap.height();
        dataStore.TransAmount = 50 / dataStore.bgImgWrapHeight;
    }
}

function moveFieldLabels() {
    $('.gfield input, .gfield textarea, .gfield select').on('focus', (e) => {
        const input = $(e.currentTarget);
        const formField = input.closest('.gfield');
        formField.addClass('focus');
    }).on('focusout', (e) => {
        const input = $(e.currentTarget);
        const formField = input.closest('.gfield');

        if (!input.val()) {
            formField.removeClass('focus');
        }
    });
}
