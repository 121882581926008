class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.win = $(window);
        this.body = $('body');

        /* mouse ++++++++++++++++++++++++++++++++++++++++++*/
        this.activeMouseFollow = false;
        this.currentFollowerWrap = undefined;
        this.currentFollower = undefined;
        this.mouseX = 0;
        this.mouseY = 0;
        this.mouseYScroll = 0;

        /* window ++++++++++++++++++++++++++++++++++++++++++*/

        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* browser ++++++++++++++++++++++++++++++++++++++++++*/

        this.isIe = false;
        this.isFirefox = false;
        this.isSafari = false;
        this.isNoIe = false;
        this.touch = false;
        this.touchBreakPoint = 929;
        this.hasScrollbar = window.innerWidth > document.documentElement.clientWidth;

        /* ids ++++++++++++++++++++++++++++++++++++++++++*/
        this.globalIds = JSON.parse(window.wpUrls.globalIds);

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* header ++++++++++++++++++++++++++++++++++++++++++*/
        this.didScroll = false;
        this.lastScrollTop = 0;
        this.delta = 5;
        this.headerHeight = $('.header').outerHeight() + 100;
        this.scrollHeader = $('#scrollHeader');

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = '';

        /* lazyload ++++++++++++++++++++++++++++++++++++++++++*/
        this.lazyLoadInstance = '';

        /* timeline ++++++++++++++++++++++++++++++++++++++++++*/
        this.timeLineItems = [];

        /* Parapllax ++++++++++++++++++++++++++++++++++++++++++*/
        this.bgImgWrap = $('[data-parallaxWrap]');
        this.bgImg = $('.bgImgWrap');
        this.bgImgWrapHeight = 0;
        this.bgImgWrapTop = 0;
        this.TransAmount = 0;
        this.imageScroll = 0;

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        this.mapMarkers = [];
        this.map = '';

        this.customerJourneyWrapper = $('.customerjourneyWrapper');
        this.customerJourneyLength = $('.customerjourneyWrapper').length;
        this.progressVertWrap = $('.progressVertWrap');
        this.vertProgressBar = $('.vertProgressBar');
        this.vertProgressBarOffsetTop = 0;
        this.vertProgressBarOffsetHeight = 0;

        this.plyrInit = '';
        this.plyr = '';
    }
}

export default (new DataStore());
